import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <div id="wrap" className="index">
        <summary>
          <ol style={{ listStyle: `none` }}>
            {posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug
              const featuredImage = post.frontmatter.featuredImage?.childImageSharp?.fluid
              const brandColor = post.frontmatter.brandColor

              return (
                <li key={post.fields.slug} className={post.frontmatter.ref}>
                  <figure>
                    <div id="flex">
                      <Image
                        fluid={featuredImage}
                        alt={title}
                        className="featuredImage"
                      />
                      <div id="info">
                      <figcaption>
                        <p className="title" style={{ color: brandColor }}>
                          {title}
                        </p>
                        <section>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: post.frontmatter.description || post.excerpt,
                            }}
                          />
                          <Link to={post.fields.slug} id="cta">View project <span>→</span></Link>
                        </section>
                      </figcaption>
                      </div>
                    </div>
                  </figure>
                </li>
              )
            })}
          </ol>
        </summary>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___ref], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          ref
          title
          description
          brandColor
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`